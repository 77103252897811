import { Pipe, PipeTransform } from '@angular/core';
import { LangService } from '../shared/lang.service';

@Pipe({
    name: 'searchpipe',
})
export class SearchPipe implements PipeTransform {

    constructor(private _lang:LangService){}

    transform(value: any, search: string, searcharea: string[]): any {
        // console.log(searcharea)
        if (search !== undefined) {
            const regex = new RegExp(search, 'i');
            const data = [];
            value.forEach(element => {
                let flag = false;
                searcharea.forEach(ele => {

                    var main = ele.split('.');
                    // console.log(ele);
                    
                    
                    // if (element[ele][langIndex] && element[ele][langIndex].match(regex)) {
                        //     flag = true;
                        // }
                        
                    if(typeof element[ele] === 'object'){
                        var langIndex = element[ele][this._lang.selectedlanguageIndex] ? this._lang.selectedlanguageIndex : 0;
                        
                        if (element[ele][langIndex] && element[ele][langIndex].match(regex)) {
                            flag = true;
                        }
                        // if (element[ele][this._lang.selectedlanguageIndex] && element[ele][this._lang.selectedlanguageIndex].match(regex)) {
                        //     flag = true;
                        // }
                    }
                    else{
                        if ( main.length > 1 ? element[main[0]][main[1]].match(regex) : element[main[0]].match(regex)) {
                            flag = true;
                        }
                    }
                });
                if (flag) {
                    data.push(element);
                }
            });
            return data;
        }
        return value;
    }
}