import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  role?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

const data: IMenuItem[] = [
  // {
  //   icon: 'iconsminds-shop-4',
  //   label: 'menu-title.dashboard',
  //   to: `${adminRoot}/dashboard`,
  //   roles: [UserRole.Admin, UserRole.Editor],
  //   // role: '/admin/dashboard'
  // },
  // {
  //   icon: 'simple-icon-notebook',
  //   label: 'menu-title.order',
  //   to: `${adminRoot}/order`,
  //   subs: [
  //     {
  //       icon: 'simple-icon-layers',
  //       label: 'menu-title.running-order',
  //       to: `${adminRoot}/order/running-orders`,
  //       subs: [
  //         {
  //           icon: 'simple-icon-basket-loaded',
  //           label: 'menu-title.orders',
  //           to: `${adminRoot}/order/running-orders/orders`,
  //           role: '/admin/orders'
  //         },
  //         {
  //           icon: 'iconsminds-scooter',
  //           label: 'menu-title.delivery',
  //           to: `${adminRoot}/order/running-orders/delivery`,
  //           role: '/admin/deliveries'
  //         },
  //         {
  //           icon: 'simple-icon-user-following',
  //           label: 'menu-title.dispatch',
  //           to: `${adminRoot}/order/running-orders/dispatch`,
  //           role: '/admin/orders'
  //         }
  //       ],
  //     },
  //     {
  //       icon: 'simple-icon-layers',
  //       label: 'menu-title.history',
  //       to: `${adminRoot}/order/history`,
  //       subs: [
  //         {
  //           icon: 'iconsminds-receipt-4',
  //           label: 'menu-title.history',
  //           to: `${adminRoot}/order/history/history-list`,
  //           role: '/admin/history'
  //         },
  //         {
  //           icon: 'simple-icon-star',
  //           label: 'menu-title.review',
  //           to: `${adminRoot}/order/history/reviews`,
  //         },
  //         // {
  //         //   icon: 'simple-icon-credit-card',
  //         //   label: 'menu-title.cancelled-delivery',
  //         //   to: `${adminRoot}/order/history/cancelled-delivery`,
  //         // },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   icon: 'iconsminds-map2',
  //   label: 'menu-title.map-view',
  //   to: `${adminRoot}/map-views`,
  //   subs: [
  //     {
  //       icon: 'iconsminds-geo2',
  //       label: 'menu-title.store-location',
  //       to: `${adminRoot}/map-views/store-location`,
  //       role: '/admin/store_location'
  //     },
  //     {
  //       icon: 'iconsminds-location-2',
  //       label: 'menu-title.provider-location',
  //       to: `${adminRoot}/map-views/provider-location`,
  //       role: '/admin/provider_location'
  //     },
  //     {
  //       icon: 'iconsminds-map-marker',
  //       label: 'menu-title.delivery-man-tracking',
  //       to: `${adminRoot}/map-views/delivery-man-tracking`,
  //       role: '/admin/location_track'
  //     }
  //   ],
  // },
  // {
  //   icon: 'iconsminds-receipt-4',
  //   label: 'menu-title.earning',
  //   to: `${adminRoot}/earning`,
  //   subs: [
  //     {
  //       icon: 'simple-icon-layers',
  //       label: 'menu-title.earning',
  //       to: `${adminRoot}/earning/order`,
  //       subs: [
  //         {
  //           icon: 'simple-icon-basket-loaded',
  //           label: 'menu-title.order-earning',
  //           to: `${adminRoot}/earning/order/order-earning`,
  //           role: '/admin/order_earning'
  //         },
  //         {
  //           icon: 'iconsminds-money-bag',
  //           label: 'menu-title.deliveryman-weekly',
  //           to: `${adminRoot}/earning/order/deliveryman-weekly`,
  //           role: '/admin/provider_weekly_earning'
  //         },
  //         {
  //           icon: 'iconsminds-shop-4',
  //           label: 'menu-title.store-earning',
  //           to: `${adminRoot}/earning/order/store-earning`,
  //           role: '/admin/store_weekly_earning'
  //         }
  //       ],
  //     },
  //     {
  //       icon: 'simple-icon-layers',
  //       label: 'menu-title.wallet',
  //       to: `${adminRoot}/earning/wallet`,
  //       subs: [
  //         {
  //           icon: 'simple-icon-wallet',
  //           label: 'menu-title.wallet-history',
  //           to: `${adminRoot}/earning/wallet/wallet-history`,
  //           role: '/admin/wallet_detail'
  //         },
  //         {
  //           icon: 'iconsminds-wallet',
  //           label: 'menu-title.wallet-request',
  //           to: `${adminRoot}/earning/wallet/wallet-request`,
  //           role: '/admin/wallet_request'
  //         },
  //         {
  //           icon: 'simple-icon-credit-card',
  //           label: 'menu-title.transaction-history',
  //           to: `${adminRoot}/earning/wallet/transaction-history`,
  //           role: '/admin/transaction_history'
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   icon: 'iconsminds-pantone',
  //   label: 'menu-title.menu',
  //   to: `${adminRoot}/menu`,
  //   subs: [
  //     {
  //       icon: 'simple-icon-layers',
  //       label: 'menu-title.menu',
  //       to: `${adminRoot}/menu/levels1`,
  //       subs: [
  //         {
  //           icon: 'simple-icon-organization',
  //           label: 'menu-title.category',
  //           to: `${adminRoot}/menu/category`,
  //         },
  //         {
  //           icon: 'simple-icon-film',
  //           label: 'menu-title.item',
  //           to: `${adminRoot}/menu/item-list`,
  //         },
  //       ],
  //     },
  //     {
  //       icon: 'simple-icon-layers',
  //       label: 'menu-title.modifier',
  //       to: `${adminRoot}/menu/levels2`,
  //       subs: [
  //         {
  //           icon: 'simple-icon-list',
  //           label: 'menu-title.modifier-group',
  //           to: `${adminRoot}/menu/modifier-group`,
  //         }
  //       ],
  //     },
  //   ],
  // },
  {
    icon: 'simple-icon-people',
    label: 'menu-title.users',
    to: `${adminRoot}/users`,
    // roles: [UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-people',
        label: 'menu-title.user',
        to: `${adminRoot}/users/user`,
        role: '/admin/users',
      },
      {
        icon: 'iconsminds-scooter',
        label: 'menu-title.patients-list',
        to: `${adminRoot}/users/patient-list`,
        role: '/admin/patient-list',
      },
      {
        icon: 'iconsminds-conference',
        label: 'menu-title.group',
        to: `${adminRoot}/users/group`,
        role: '/admin/group',
      },
      /*,
      {
        icon: 'iconsminds-scooter',
        label: 'menu-title.delivery-boy-list',
        to: `${adminRoot}/users/delivery-boy-list`,
        role: '/admin/providers'
      },
      {
        icon: 'iconsminds-shop-2',
        label: 'menu-title.store',
        to: `${adminRoot}/users/store`,
        role: '/admin/stores'
      }*/
      {
        icon: 'iconsminds-administrator',
        label: 'menu-title.sub-admin',
        to: `${adminRoot}/users/sub-admin`,
        role: '/admin/sub-admin',
      },
      {
        icon: 'iconsminds-business-mens',
        label: 'menu-title.hospital-user-list',
        to: `${adminRoot}/users/hospital-user-list`,
        role: '/admin/hospital-user-list',
      },
    ],
  },
  // {
  //   icon: 'iconsminds-ship',
  //   label: 'menu-title.delivery-info',
  //   to: `${adminRoot}/delivery-info`,
  //   // roles: [UserRole.Editor],
  //   subs: [
  //     {
  //       icon: 'iconsminds-box-close',
  //       label: 'menu-title.delivery',
  //       to: `${adminRoot}/delivery-info/delivery`,
  //       role: '/admin/delivery'
  //     },
  //     {
  //       icon: 'simple-icon-globe',
  //       label: 'menu-title.country-city-info',
  //       to: `${adminRoot}/delivery-info/country-city-info`,
  //       role: '/admin/country'
  //     },
  //     {
  //       icon: 'iconsminds-billing',
  //       label: 'menu-title.d-fees-info',
  //       to: `${adminRoot}/delivery-info/d-fees-info`,
  //       role: '/admin/service'
  //     }
  //   ]
  // },

  {
    icon: 'iconsminds-data-center',
    label: 'menu.master-data',
    to: `${adminRoot}/master_data`,
    subs: [
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.province-list',
        to: `${adminRoot}/master_data/province-list`,
        role: '/master_data/province-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.district-list',
        to: `${adminRoot}/master_data/district-list`,
        role: '/master_data/district-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.sub-district-list',
        to: `${adminRoot}/master_data/subdistrict-list`,
        role: '/master_data/subdistrict-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.hospital-list',
        to: `${adminRoot}/master_data/hospital-list`,
        role: '/master_data/hospital-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.nationality-list',
        to: `${adminRoot}/master_data/nationality-list`,
        role: '/master_data/nationality-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.human-skin-color-list',
        to: `${adminRoot}/master_data/human-skin-color-list`,
        role: '/master_data/human-skin-color-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.blood-group-list',
        to: `${adminRoot}/master_data/blood-group-list`,
        role: '/master_data/blood-group-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.voip-user-list',
        to: `${adminRoot}/master_data/voip-user-list`,
        role: '/master_data/voip-user-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.staff-type-list',
        to: `${adminRoot}/master_data/staff-type-list`,
        role: '/master_data/staff-type-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.underlying-disease-list',
        to: `${adminRoot}/master_data/underlying-disease-list`,
        role: '/master_data/underlying-disease-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.ava-box-list',
        to: `${adminRoot}/master_data/ava-box-list`,
        role: '/master_data/ava-box-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.watch-list',
        to: `${adminRoot}/master_data/watch-list`,
        role: '/master_data/watch-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.medical-devices-list',
        to: `${adminRoot}/master_data/medical-devices-list`,
        role: '/master_data/medical-devices-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.mobile-operator-list',
        to: `${adminRoot}/master_data/mobile-operator-list`,
        role: '/master_data/mobile-operator-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.ward-list',
        to: `${adminRoot}/master_data/ward-list`,
        role: '/master_data/ward-list',
      },
    ],
  },

  {
    icon: 'iconsminds-router',
    label: 'menu.amy-ava',
    to: `${adminRoot}/amy_ava`,
    // roles: [UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.amy-box-list',
        to: `${adminRoot}/amy_ava/amy-box-list`,
        role: '/amy_ava/amy-box-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.amy-device-list',
        to: `${adminRoot}/amy_ava/amy-device-list`,
        role: '/amy_ava/amy-device-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.amy-box-monitoring',
        to: `${adminRoot}/amy_ava/amy-box-monitoring`,
        role: '/amy_ava/amy-box-monitoring',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.amy-box-transaction',
        to: `${adminRoot}/amy_ava/amy-box-transaction`,
        role: '/amy_ava/amy-box-transaction',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.amy-sensor-list',
        to: `${adminRoot}/amy_ava/amy-sensor-list`,
        role: '/amy_ava/amy-sensor-list',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.amy-sensor-transaction',
        to: `${adminRoot}/amy_ava/amy-sensor-transaction`,
        role: '/amy_ava/amy-sensor-transaction',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.esp32-transaction',
        to: `${adminRoot}/amy_ava/esp32-transaction`,
        role: '/amy_ava/esp32-transaction',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.mfc-transaction',
        to: `${adminRoot}/amy_ava/mfc-transaction`,
        role: '/amy_ava/mfc-transaction',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.mfc-HV-01-box',
        to: `${adminRoot}/amy_ava/mfc-HV01-box`,
        role: '/amy_ava/mfc-HV01-box',
      },
    ],
  },

  {
    icon: 'iconsminds-watch',
    label: 'menu.amy-watch',
    to: `${adminRoot}/amy_watch`,
    // roles: [UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.amy-watch-debug-transaction',
        to: `${adminRoot}/amy_watch/amy-watch-debug-transaction`,
        role: '/amy_watch/amy-watch-debug-transaction',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.tcp-watch-debug-transaction',
        to: `${adminRoot}/amy_watch/tcp-watch-debug-transaction`,
        role: '/amy_watch/tcp-watch-debug-transaction',
      },
      {
        icon: 'iconsminds-credit-card',
        label: 'menu-title.amy-watch-monitoring',
        to: `${adminRoot}/amy_watch/amy-watch-monitoring`,
        role: '/amy_watch/amy-watch-monitoring',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.watch-management',
        to: `${adminRoot}/amy_watch/watch-management`,
        role: '/amy_watch/watch-management',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.amy-watch-transaction',
        to: `${adminRoot}/amy_watch/amy-watch-transaction`,
        role: '/amy_watch/amy-watch-transaction',
      },
      {
        icon: 'iconsminds-location-2',
        label: 'menu-title.watch-user-location',
        to: `${adminRoot}/amy_watch/watch-user-location`,
        role: '/amy_watch/watch-user-location',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.watch-push-notification',
        to: `${adminRoot}/amy_watch/watch-push-notification`,
        role: '/amy_watch/watch-push-notification',
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'menu.watch-location-transaction',
        to: `${adminRoot}/amy_watch/watch-location-transaction`,
        role: '/amy_watch/watch-location-transaction',
      },
    ],
  },

  {
    icon: 'iconsminds-notepad',
    label: 'menu.forms',
    to: `${adminRoot}/forms`,
    // roles: [UserRole.Editor],
    subs: [
      {
        icon: 'iconsminds-notepad',
        label: 'label-title.request-ambulances-form',
        to: `${adminRoot}/forms/request-ambulances-form`,
        role: '/forms/request-ambulances-form',
      },
      {
        icon: 'iconsminds-book',
        label: 'label-title.transfer-recording-for-ambulance',
        to: `${adminRoot}/forms/transfer-recording-for-ambulance`,
        role: '/forms/transfer-recording-for-ambulance',
      },
      {
        icon: 'iconsminds-book',
        label: 'label-title.transporatation-treatement',
        to: `${adminRoot}/forms/transporatation-treatement`,
        role: '/forms/transporatation-treatement',
      },
      {
        icon: 'iconsminds-book',
        label: 'label-title.operation-form',
        to: `${adminRoot}/forms/operation-form`,
        role: '/forms/operation-form',
      },
    ],
  },

  {
    icon: 'iconsminds-files',
    label: 'label-title.activity-logs',
    to: `${adminRoot}/activity_logs`,
    // roles: [UserRole.Editor],
    subs: [
      {
        icon: 'iconsminds-files',
        label: 'label-title.activity-logs',
        to: `${adminRoot}/activity_logs/activity-log`,
        role: '/activity_logs/activity-log',
      },
    ],
  },

  {
    icon: 'simple-icon-bell',
    label: 'menu.notification',
    to: `${adminRoot}/notification`,
    // roles: [UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-speedometer',
        label: 'menu.notify-transaction',
        to: `${adminRoot}/notification/notify-transaction`,
        role: '/notification/notify-transaction',
      },
    ],
  },

  // {
  //   icon: 'iconsminds-data-center',
  //   label: 'menu.master-data',
  //   to: `${adminRoot}/pages`,
  //   subs: [
  //     // {
  //     //   label: 'menu.authorization',
  //     //   to: '/user',
  //     //   // roles: [UserRole.Editor],
  //     //   subs: [
  //     //     {
  //     //       icon: 'simple-icon-user-following',
  //     //       label: 'menu.login',
  //     //       to: '/user/login',
  //     //       newWindow: true,
  //     //     },
  //     //     {
  //     //       icon: 'simple-icon-user-follow',
  //     //       label: 'menu.register',
  //     //       to: '/user/register',
  //     //       newWindow: true,
  //     //     },
  //     //     {
  //     //       icon: 'simple-icon-user-unfollow',
  //     //       label: 'menu.forgot-password',
  //     //       to: '/user/forgot-password',
  //     //       newWindow: true,
  //     //     },
  //     //     {
  //     //       icon: 'simple-icon-user-following',
  //     //       label: 'menu.reset-password',
  //     //       to: '/user/reset-password',
  //     //       newWindow: true,
  //     //     },
  //     //   ],
  //     // },

  //     {
  //       label: 'menu.master-data-list',
  //       to: `${adminRoot}/pages/product`,
  //       subs: [
  //         {
  //           icon: 'simple-icon-credit-card',
  //           label: 'menu.province-list',
  //           to: `${adminRoot}/pages/product/province-list`,
  //         },
  //         {
  //           icon: 'simple-icon-credit-card',
  //           label: 'menu.district-list',
  //           to: `${adminRoot}/pages/product/district-list`,
  //         },
  //         {
  //           icon: 'simple-icon-credit-card',
  //           label: 'menu.sub-district-list',
  //           to: `${adminRoot}/pages/product/subdistrict-list`,
  //         },
  //         {
  //           icon: 'simple-icon-credit-card',
  //           label: 'menu.hospital-list',
  //           to: `${adminRoot}/pages/product/hospital-list`,
  //         }
  //       ],
  //     }

  //   ],
  // },
  {
    icon: 'iconsminds-three-arrow-fork',
    label: 'menu-title.settings',
    to: `${adminRoot}/settings`,
    // roles: [UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.basic-settings',
        to: `${adminRoot}/settings/basic-settings`,
        subs: [
          {
            icon: ' iconsminds-administrator',
            label: 'menu-title.admin',
            to: `${adminRoot}/settings/basic-settings/admin`,
            role: '/setting/admin',
          },
          {
            icon: 'iconsminds-administrator',
            label: 'label-title.admin-roles',
            to: `${adminRoot}/settings/basic-settings/admin-roles`,
            role: '/setting/admin-roles',
          },
          // {
          //   icon: 'iconsminds-files',
          //   label: 'label-title.activity-logs',
          //   to: `${adminRoot}/settings/basic-settings/activity-logs`,
          //   role: '/setting/activity-logs'
          // },
          // {
          //   icon: 'iconsminds-files',
          //   label: 'menu-title.document',
          //   to: `${adminRoot}/settings/basic-settings/document`,
          //   role: '/admin/document'
          // },
          {
            icon: 'iconsminds-box-with-folders',
            label: 'menu-title.language',
            to: `${adminRoot}/settings/basic-settings/language`,
            role: '/setting/language',
          },
          {
            icon: 'iconsminds-mail-settings',
            label: 'label-title.privacy-policy',
            to: `${adminRoot}/settings/basic-settings/terms-settings`,
            role: '/setting/terms-settings',
          },
        ],
      } /*,
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.discount',
        to: `${adminRoot}/settings/discount`,
        subs: [
          {
            icon: 'iconsminds-information',
            label: 'menu-title.promo-code',
            to: `${adminRoot}/settings/discount/offer`,
            role: '/admin/promotions'
          },
          {
            icon: 'simple-icon-picture',
            label: 'menu-title.advertise',
            to: `${adminRoot}/settings/discount/advertise`,
            role: '/admin/advertise'
          },
          {
            icon: 'iconsminds-handshake',
            label: 'menu-title.referral-code',
            to: `${adminRoot}/settings/discount/referral`,
            role: '/admin/referral_detail'
          },
        ],
      },
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.template',
        to: `${adminRoot}/settings/template`,
        subs: [
          {
            icon: 'iconsminds-mail-settings',
            label: 'menu-title.email-settings',
            to: `${adminRoot}/settings/template/email-settings`,
            role: '/admin/email'
          },
          {
            icon: 'iconsminds-mail-settings',
            label: 'menu-title.sms-settings',
            to: `${adminRoot}/settings/template/sms-settings`,
            role: '/admin/sms'
          },
          {
            icon: 'simple-icon-screen-smartphone',
            label: 'menu-title.mass-notification',
            to: `${adminRoot}/settings/template/mass-notification`,
          }
        ],
      },*/,
    ],
  },
];

export default data;

// import { environment } from 'src/environments/environment';
// import { UserRole } from '../shared/auth.roles';
// const adminRoot = environment.adminRoot;

// export interface IMenuItem {
//   id?: string;
//   icon?: string;
//   label: string;
//   to: string;
//   newWindow?: boolean;
//   subs?: IMenuItem[];
//   roles?: UserRole[];
// }

// const data: IMenuItem[] = [
//   {
//     icon: 'iconsminds-shop-4',
//     label: 'menu-title.dashboard',
//     to: `${adminRoot}/vien`,
//     roles: [UserRole.Admin, UserRole.Editor],
//     subs: [
//       {
//         icon: 'simple-icon-paper-plane',
//         label: 'menu-title.start',
//         to: `${adminRoot}/vien/start`,
//         // roles: [UserRole.Admin],
//       },
//     ],
//   },
//   {
//     icon: 'simple-icon-notebook',
//     label: 'menu-title.order',
//     to: `${adminRoot}`,
//     subs: [
//       {
//         icon: 'iconsminds-shopping-cart',
//         label: 'menu-title.create-order',
//         to: `${adminRoot}/create-order`
//       },
//       {
//         icon: 'simple-icon-layers',
//         label: 'menu-title.running-order',
//         to: `${adminRoot}/order`,
//         subs: [
//           {
//             icon: 'simple-icon-user-following',
//             label: 'menu-title.today-order',
//             to: `${adminRoot}/order/today-orders`,
//           },
//           {
//             icon: 'simple-icon-user-follow',
//             label: 'menu-title.tomorrow-order',
//             to: `${adminRoot}/order/tomorrow-orders`,
//           },
//           {
//             icon: 'simple-icon-user-unfollow',
//             label: 'menu-title.schedule-order',
//             to: `${adminRoot}/order/schedule-orders`,
//           },
//           {
//             icon: 'simple-icon-user-following',
//             label: 'menu-title.delivery',
//             to: `${adminRoot}/menu/levels/third-level-2`,
//           },
//           {
//             icon: 'simple-icon-user-following',
//             label: 'menu-title.dispatch',
//             to: `${adminRoot}/order/dispatch`,
//           },
//         ],
//       },
//       {
//         icon: 'simple-icon-layers',
//         label: 'menu-title.history',
//         to: `${adminRoot}/history`,
//         subs: [
//           {
//             icon: 'simple-icon-credit-card',
//             label: 'menu-title.current-week',
//             to: `${adminRoot}/history/current-week`,
//           },
//           {
//             icon: 'simple-icon-list',
//             label: 'menu-title.current-month',
//             to: `${adminRoot}/history/current-month`,
//           },
//           {
//             icon: 'simple-icon-grid',
//             label: 'menu-title.history',
//             to: `${adminRoot}/history/history-list`,
//           },
//           {
//             icon: 'simple-icon-grid',
//             label: 'menu-title.reviews',
//             to: `${adminRoot}/history/reviews`,
//           },
//           {
//             icon: 'simple-icon-picture',
//             label: 'menu-title.details',
//             to: `${adminRoot}/menu/levels/third-level-2`,
//           }
//         ],
//       },
//     ],
//   },
//   {
//     icon: 'iconsminds-air-balloon-1',
//     label: 'menu-title.earning',
//     to: `${adminRoot}/earning`,
//     subs: [
//       {
//         icon: 'iconsminds-credit-card',
//         label: 'menu-title.current-week',
//         to: `${adminRoot}/earning/current-week`,
//       }, {
//         icon: 'simple-icon-list',
//         label: 'menu-title.current-month',
//         to: `${adminRoot}/earning/current-month`,
//       }, {
//         icon: 'simple-icon-grid',
//         label: 'menu-title.earning',
//         to: `${adminRoot}/earning/earnings`,
//       }
//     ],
//   },
//   {
//     icon: 'iconsminds-pantone',
//     label: 'menu-title.menu',
//     to: `${adminRoot}/menu`,
//     subs: [
//       {
//         icon: 'simple-icon-layers',
//         label: 'menu-title.menu',
//         to: `${adminRoot}/menu/levels`,
//         subs: [
//           {
//             icon: 'simple-icon-notebook',
//             label: 'menu-title.category',
//             to: `${adminRoot}/menu/category`,
//           },
//           {
//             icon: 'simple-icon-puzzle',
//             label: 'menu-title.item',
//             to: `${adminRoot}/menu/item-list`,
//           },
//         ],
//       },
//       {
//         icon: 'simple-icon-layers',
//         label: 'menu-title.modifier',
//         to: `${adminRoot}/menu/levels`,
//         subs: [
//           {
//             icon: 'simple-icon-screen-desktop',
//             label: 'menu-title.modifier-group',
//             to: `${adminRoot}/menu/modifier-group`,
//           },
//           {
//             icon: 'simple-icon-mouse',
//             label: 'menu-title.filter',
//             to: `${adminRoot}/menu/levels/third-level-2`,
//           },
//         ],
//       },
//     ],
//   },
//   {
//     icon: 'simple-icon-people',
//     label: 'menu-title.users',
//     to: `${adminRoot}/users`,
//     // roles: [UserRole.Editor],
//     subs: [
//       {
//         icon: 'simple-icon-user',
//         label: 'menu-title.user',
//         to: `${adminRoot}/users/user`,
//       },
//       {
//         icon: 'simple-icon-user',
//         label: 'menu-title.delivery-boy-list',
//         to: `${adminRoot}/users/delivery-boy-list`,
//       }
//     ],
//   },
//   {
//     icon: 'iconsminds-ship',
//     label: 'menu-title.delivery-info',
//     to: `${adminRoot}/delivery-info`,
//     // roles: [UserRole.Editor],
//     subs: [
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.dInfo',
//         to: `${adminRoot}/delivery-info/d-Info`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.country-city-Info',
//         to: `${adminRoot}/delivery-info/country-city-Info`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.d-fees-Info',
//         to: `${adminRoot}/delivery-info/d-fees-Info`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.promo-code',
//         to: `${adminRoot}/delivery-info/promoCode`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.advertise',
//         to: `${adminRoot}/delivery-info/advertise`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.w-history',
//         to: `${adminRoot}/delivery-info/w-history`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.w-request',
//         to: `${adminRoot}/delivery-info/w-request`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.order-list',
//         to: `${adminRoot}/delivery-info/order-list`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.deliveryman-track',
//         to: `${adminRoot}/delivery-info/deliveryman-track`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.deliveryman-location',
//         to: `${adminRoot}/delivery-info/deliveryman-location`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.store-location',
//         to: `${adminRoot}/delivery-info/store-location`,
//       },
//       {
//         icon: 'iconsminds-information',
//         label: 'menu-title.mass-notification',
//         to: `${adminRoot}/delivery-info/mass-notification`,
//       },
//     ]
//   },
//   {
//     icon: 'iconsminds-clothing-store',
//     label: 'menu-title.sub-stores',
//     to: `${adminRoot}/sub-stores`,
//     // roles: [UserRole.Editor],
//     subs: [
//       {
//         icon: 'iconsminds-clothing-store',
//         label: 'menu-title.storeList',
//         to: `${adminRoot}/sub-stores/storeList`,
//       },
//       {
//         icon: 'simple-icon-grid',
//         label: 'menu-title.sub-stores-list',
//         to: `${adminRoot}/sub-stores/list`,
//       }
//     ]
//   },
//   {
//     icon: 'iconsminds-information',
//     label: 'menu-title.above',
//     to: `${adminRoot}/above`,
//     // roles: [UserRole.Editor],
//     subs: [
//       {
//         icon: ' iconsminds-administrator',
//         label: 'menu-title.settings',
//         to: `${adminRoot}/above/settings`,
//       },
//       {
//         icon: 'iconsminds-files',
//         label: 'menu-title.document',
//         to: `${adminRoot}/above/document`,
//       }
//     ]
//   },
//   {
//     icon: 'iconsminds-three-arrow-fork',
//     label: 'menu-title.settings',
//     to: `${adminRoot}/settings`,
//     // roles: [UserRole.Editor],
//     subs: [
//       {
//         icon: 'simple-icon-settings',
//         label: 'menu-title.store-settings',
//         to: `${adminRoot}/settings/store-settings`,
//       },
//       {
//         icon: 'simple-icon-settings',
//         label: 'menu-title.delivery-settings',
//         to: `${adminRoot}/settings/delivery-settings`,
//       },
//       {
//         icon: 'simple-icon-settings',
//         label: 'menu-title.store-open-close-settings',
//         to: `${adminRoot}/settings/store-open-close-settings`,
//       },
//       {
//         icon: 'iconsminds-mail-settings',
//         label: 'menu-title.email-settings',
//         to: `${adminRoot}/settings/email-settings`,
//       },
//       {
//         icon: 'iconsminds-mail-settings',
//         label: 'menu-title.sms-settings',
//         to: `${adminRoot}/settings/sms-settings`,
//       },
//       {
//         icon: 'simple-icon-key',
//         label: 'menu-title.google-key-settings',
//         to: `${adminRoot}/settings/google-key-settings`,
//       },
//       {
//         icon: 'simple-icon-paypal',
//         label: 'menu-title.payment-gateway-settings',
//         to: `${adminRoot}/settings/payment-gateway-settings`,
//       },
//       {
//         icon: 'iconsminds-gears',
//         label: 'menu-title.user-website-settings',
//         to: `${adminRoot}/settings/user-website-settings`,
//       },
//       {
//         icon: 'iconsminds-gears',
//         label: 'menu-title.payment',
//         to: `${adminRoot}/settings/payment`,
//       },
//     ],
//   },
//   {
//     icon: 'iconsminds-bucket',
//     label: 'menu-title.offers',
//     to: `${adminRoot}/offers`,
//     // roles: [UserRole.Editor],
//     subs: [
//       {
//         icon: 'iconsminds-financial',
//         label: 'menu-title.offer',
//         to: `${adminRoot}/offers/offer`,
//       }
//     ]
//   },
//   {
//     icon: 'iconsminds-library',
//     label: 'menu-title.profile',
//     to: `${adminRoot}/profile`,
//   },
// ];

// export default data;
