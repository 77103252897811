import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: any;

  constructor() {
    this.setupSocketConnection();
  } 
     
  // setup socket
  setupSocketConnection() {
    this.socket = io(environment.socketUrl, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax : 5000,
      reconnectionAttempts: 5,
      query: {
        token: 'test-auth-token'
      }
    });
  }

  /** @deprecated use listener insted of listen*/
  listen = (eventName: string) => {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      });
    });
  }

  checkConnection = () => {
    setTimeout(() => {
      if(!this.socket.connected){
        this.socket = io(environment.socketUrl, {
          reconnection: true,
          reconnectionDelay: 1000,
          reconnectionDelayMax : 5000,
          reconnectionAttempts: 5,
          query: {
            token: 'test-auth-token'
          }
        });
      }
    }, 3000);
  }

  listener = (eventName: string) => {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      });
    });
  }

  emit = (eventName, data) => {
    this.socket.emit(eventName, data);
  }
  removeListener = (eventName, data) => {
    this.socket.removeListener(eventName, data);
  }

  offListener = (eventName) => {
    this.socket.removeListener(eventName);
  }

  connectRoom = (roomId) => {
    //console.log('connectRoom');
    this.socket.emit('room', roomId);
  }
  disconnetRoom = (roomId) => {
    this.socket.emit('removeRoom', roomId);
  }
}
