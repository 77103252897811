import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: string, type: string = ''): string {

    if (type != '' && type == 'short') {

      if (!value) return '';

      const timestamp = new Date(value).getTime();
      const now = new Date().getTime();
      const diff = now - timestamp;

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      let day = `${days}D`
      if (days == 0) {
        day = ''
      }

      return `${day} ${hours}H ${minutes}M`;
    }else if (type != '' && type == 'customDateFormat') {
      
      if (!value) return '';

      return formatDate(value, 'dd MMM yyyy HH:mm:ss', 'en-US');
      
    }else {
      if (!value) return '';

      const timestamp = new Date(value).getTime();
      const now = new Date().getTime();
      const diff = now - timestamp;

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

      if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
      } else {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
      }
    }

  }

}
