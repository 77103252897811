import { Component, OnInit, OnDestroy, HostListener, NgZone, ViewChild, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { LangService, Language } from 'src/app/shared/lang.service';
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { AdminSettingService } from 'src/app/services/admin-setting.service';
import { Helper } from 'src/app/shared/helper';
import { CommonService } from 'src/app/services/common.service';
import { SocketService } from 'src/app/shared/socket.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DatePipe } from '@angular/common';
import { IProduct } from 'src/app/data/api.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import mapboxgl from 'mapbox-gl';
@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  providers: [DatePipe]
})
export class TopnavComponent implements OnInit, OnDestroy {
  buyUrl = environment.buyUrl;
  adminRoot = environment.adminRoot;
  sidebar: ISidebar;
  subscription: Subscription;
  displayName = 'Sarah Cortney';
  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  adminData: any;
  appName: any;

  notifications = [];
  interval: any = null;
  admin_data: any;
  data: any[] = [];
  totalItem = 0;
  currentPage = 1;
  itemsPerPage = 10;
  searchBy = 'IMEI';

  modalRef: BsModalRef;
  config = {
    ignoreBackdropClick: true,
    class: 'modal-xxl modal-center sos-model',
    keyboard: false,
    backdropClass: "test"
  };

  notificationData: any;
  map: mapboxgl.Map;
  is_show_map;
  is_open_popup = false;

  @ViewChild('template', { static: true }) template: TemplateRef<any>;

  constructor(
    private sidebarService: SidebarService,
    private authService: AuthService,
    private router: Router,
    private langService: LangService,
    private commonService: CommonService,
    public helper: Helper,
    private socketService: SocketService,
    private _adminSettingService: AdminSettingService,
    public datepipe: DatePipe,
    private ngZone: NgZone,
    private modalService: BsModalService
  ) {
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
    this.getAdminData()
    this.socketService.checkConnection();
  }

  getAdminData() {
    this.appName = localStorage.getItem('appName')
    // console.log(this.appName)
  }

  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  onLanguageChange(lang): void {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
  }

  async ngOnInit(): Promise<void> {
    this._adminSettingService._settingObservable.subscribe(() => {
      this.getAdminData()
    })
    if (await this.authService.getUser()) {
      this.displayName = await this.authService.getUser().then((user) => {
        return user.displayName;
      });
    }
    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
        // console.error(`An error occurred: ${err.message}`);
      }
    );



    this.loadData(this.itemsPerPage, this.currentPage, '', this.searchBy);
    this.startInterval()
    // this.socketService.listener("sos_alert").subscribe( (res) =>{
    //   var res_data = JSON.parse(JSON.stringify(res));
    //   console.log(res_data)
    //   console.log('--------------  res_data    sos_alert ------------ ')

    //   this.notifications.push(res_data);

    //   this.show_sos_alert(res_data)
    // });
  }

  _initMap() {
    mapboxgl.accessToken = 'pk.eyJ1Ijoia2l0a2Ftb24iLCJhIjoiY2x0NTlwanNhMDl0bDJqbWViZ3NmcHZ5eSJ9.6sMSW94GqkFRPUmhphggvg';
    this.map = new mapboxgl.Map({
      container: 'patient_map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: 17,
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('load', () => {
      // Map is loaded and ready
    });

    let icon_url = 'assets/img/map_pin_images/Patient/';
    let icon_name = ''
    icon_name = 'pin_sos.png';

    icon_url = icon_url + icon_name;

    let image_url = 'assets/img/map_pin_images/Patient/avatar.png';
    if (this.notificationData?.patient_detail.profile_image) {
      image_url = this.helper.image_url + this.notificationData?.patient_detail.profile_image
    }


    let content = `
          <div class="container" style="width: 50px; height: 55px;" >
              <img class="bg_image" src="`+ icon_url + `" />
              <img class="center" src="`+ image_url + `"/>
          </div>

          <style>
            .container {
              height: 500px;
              position: relative;
              // border: 3px solid #dbe4ed; /* Border color is optional */
              padding: 0;
            }

            .bg_image{
              width: 100%;
              height: 100%;
              position: absolute;
            }

            .center {
              border-radius: 26px;
              margin: 0;
              width: 34px;
              height: 32px;
              position: absolute;
              top: 41% !important;
              left: 49%;
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            }

          </style>`

    if(this.notificationData?.notify_type == 27 || this.notificationData?.notify_type == 28){
      if (this.notificationData?.patient_detail.location && this.notificationData?.patient_detail.location[0]) {
        const el = document.createElement('div');
        el.className = 'custom-marker';
        el.style.backgroundImage = 'url(assets/img/map_pin_images/Patient/pin_online.png)';
        el.style.width = '50px';
        el.style.height = '50px';
        el.innerHTML = content;
        const marker = new mapboxgl.Marker(el)
          .setLngLat([this.notificationData?.location[1], this.notificationData?.location[0]]) // <-- Correct order: [longitude, latitude]
          .addTo(this.map);
  
        this.map.setCenter([this.notificationData?.location[1], this.notificationData?.location[0]]);
      }
    }
    this.setHomePin();
    this.setMapPin();
  }

  setHomePin(){
    let home_img = 'assets/img/map_pin_images/Patient/avatar.png';
    if (this.notificationData?.patient_detail.profile_image) {
      home_img = this.helper.image_url + this.notificationData?.patient_detail.profile_image
    }


    let homePin = `
    <div class="container" style="width: 50px; height: 55px;" >
        <img class="bg_image" src="assets/img/map_pin_images/Patient/pin_online.png" />
        <img class="center" src="`+ home_img + `"/>
    </div>

    <style>
      .container {
        height: 500px;
        position: relative;
        // border: 3px solid #dbe4ed; /* Border color is optional */
        padding: 0;
      }

      .bg_image{
        width: 100%;
        height: 100%;
        position: absolute;
      }

      .center {
        border-radius: 26px;
        margin: 0;
        width: 34px;
        height: 32px;
        position: absolute;
        top: 41% !important;
        left: 49%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

    </style>`
    if(this.notificationData?.patient_detail?.lang1 && this.notificationData?.patient_detail?.lat1){
      const el = document.createElement('div');
      el.className = 'custom-marker';
      el.style.backgroundImage = 'url(assets/img/map_pin_images/Patient/pin_online.png)';
      el.style.width = '50px';
      el.style.height = '50px';
      el.innerHTML = homePin;
      const marker = new mapboxgl.Marker(el)
        .setLngLat([this.notificationData?.patient_detail?.lang1 , this.notificationData?.patient_detail?.lat1]) // <-- Correct order: [longitude, latitude]
        .addTo(this.map);
    }
    if(this.notificationData?.patient_detail?.lang2 && this.notificationData?.patient_detail?.lat2){
      const el = document.createElement('div');
      el.className = 'custom-marker';
      el.style.backgroundImage = 'url(assets/img/map_pin_images/Patient/pin_online.png)';
      el.style.width = '50px';
      el.style.height = '50px';
      el.innerHTML = homePin;
      const marker = new mapboxgl.Marker(el)
        .setLngLat([this.notificationData?.patient_detail?.lang2 , this.notificationData?.patient_detail?.lat2]) // <-- Correct order: [longitude, latitude]
        .addTo(this.map);
    }
  }

  setMapPin() {
    let icon_url = 'assets/img/map_pin_images/Patient/';
    let icon_name = ''
    icon_name = 'pin_online.png';

    icon_url = icon_url + icon_name;

    let image_url: any = "https://takhun.firstcare.work/assets/img/home-page/Group@2x.png";


    let content = `
      <div class="container" style="width: 50px; height: 55px;" >
      <img class="bg_image" src="`+ icon_url + `" />
      <img class="center" src="`+ image_url + `"/>
  </div>

  <style>
    .container {
      height: 500px;
      position: relative;
      // border: 3px solid #dbe4ed; /* Border color is optional */
      padding: 0;
    }

    .bg_image{
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .center {
      border-radius: 26px;
      margin: 0;
      width: 34px;
      height: 32px;
      position: absolute;
      top: 41% !important;
      left: 49%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

  </style>`
    if (this.notificationData?.hospital_detail?.location && this.notificationData?.hospital_detail?.location[0] && this.notificationData?.hospital_detail?.location[1]) {
      const el = document.createElement('div');
      el.className = 'custom-marker';
      el.style.backgroundImage = 'url(https://takhun.firstcare.work/assets/img/home-page/Group@2x.png)';
      el.style.width = '75px';
      el.style.height = '75px';
      el.innerHTML = content;

      const marker = new mapboxgl.Marker(el)
        .setLngLat([this.notificationData?.hospital_detail?.location[1],this.notificationData?.hospital_detail?.location[0]]) 
        .addTo(this.map);
      }
      this.fitMap();
  }

  fitMap(){
    let array :any = [];
    if (this.notificationData?.hospital_detail?.location && this.notificationData?.hospital_detail?.location[0] && this.notificationData?.hospital_detail?.location[1]) {
      array.push([this.notificationData?.hospital_detail?.location[1] , this.notificationData?.hospital_detail?.location[0]])
    }
    if(this.notificationData?.notify_type == 27 || this.notificationData?.notify_type == 28){
      if (this.notificationData?.location[1] && this.notificationData?.location[0]) {
        array.push([this.notificationData?.location[1] , this.notificationData?.location[0]])
      }else{
        this.is_show_map = false;
      }
    }
    if(this.notificationData?.patient_detail?.lang1 && this.notificationData?.patient_detail?.lat1){
      array.push([this.notificationData?.patient_detail?.lang1 , this.notificationData?.patient_detail?.lat1])
    }
    if(this.notificationData?.patient_detail?.lang2 && this.notificationData?.patient_detail?.lat2){
      array.push([this.notificationData?.patient_detail?.lang2 , this.notificationData?.patient_detail?.lat2])
    }
    if (array.length > 0) {
      var bounds = new mapboxgl.LngLatBounds();
      array.forEach(point => {
        bounds.extend(point);
      }); 
      this.map.fitBounds(bounds, { padding: 50 });
    }else{
      this.is_show_map = false;
    }
  }

  show_sos_alert(data) {
    if(this.is_open_popup != true){
      this.is_show_map = true;
      this.is_open_popup = true;
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.notificationData = data;
      this.modalRef = this.modalService.show(this.template, this.config);
      setTimeout(() => {
        if (this.notificationData?.location[1] && this.notificationData?.location[0]) {
          this._initMap();
        }
        }, 1000);
      let music: any;
      if (this.notificationData?.notify_type == 27 || this.notificationData?.notify_type == 28) {
        music = document.getElementById("music");
      } else {
        music = document.getElementById("musicVS");
      }
      music.play();
    }else{
      this.startInterval();
    }
  }

  isConfirmed(status) {
    this.admin_data = JSON.parse(localStorage.getItem('adminData'))
    let json = { id: this.notificationData._id, type: 1 , user_id :  this.admin_data._id , status}
    this.helper.http_post_method_requester_for_notification(this.helper.METHODS.UPDATE_NOTIFY, json).then(res_data => {
      if (res_data.success) {
        this.close();
      }
    })
  }

  close(){
    this.loadData(this.itemsPerPage, this.currentPage, '', '');
    this.startInterval();
    this.is_show_map = true;
    this.is_open_popup = false;
    let music: any;
    if (this.notificationData?.notify_type == 27 || this.notificationData?.notify_type == 28) {
      music = document.getElementById("music");
    } else {
      music = document.getElementById("musicVS");
    }
    music.pause();
    music.currentTime = 0;
    this.modalRef.hide();
  }

  loadData(pageSize: number = 10, currentPage: number = 1, search: string = '', searchBy: string = ''): void {
    this.admin_data = JSON.parse(localStorage.getItem('adminData'))
    let group_id = this.admin_data.group_id ? this.admin_data.group_id : null
    let req_body = {
      pageSize: pageSize,
      currentPage: currentPage,
      search: search,
      searchBy: searchBy,
      group_id
    }

    this.helper.http_post_method_requester(this.helper.METHODS.GET_NOTIFY, req_body, false, true).then(res_data => {
      this.data = res_data.transactions;

      this.notifications = []
      for (let i = 0; i < this.data.length; i++) {
        this.notifications.push(this.data[i]);

        let status = 0;
        for (let index = 0; index < this.data[i]?.read_info?.length; index++) {
          if (this.data[i]?.read_info?.[index].status == 3) {
            status = 3;
          }
        }
        if(this.is_open_popup == false && status != 3){
          this.checkTimeDifference(this.data[i])
        }
      }
      this.totalItem = res_data.transactions.length;
    })

  }

  startInterval(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      this.loadData(this.itemsPerPage, this.currentPage, '', this.searchBy);
    }, 8000);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    clearInterval(this.interval);
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  onSignOut(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.authService.signOut()
  }

  searchKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) {
        input.classList.remove('mobile-view');
      }
      this.searchKey = '';
    }
  }

  searchAreaClick(event): void {
    event.stopPropagation();
  }
  searchClick(event): void {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search(): void {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate([this.adminRoot + '/#'], {
        queryParams: { key: this.searchKey.toLowerCase().trim() },
      });
      this.searchKey = '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }

  checkTimeDifference(updateTime): void {
    const currentTime = new Date();
    let time = new Date(updateTime.created_at)
    const differenceInSeconds = Math.abs((currentTime.getTime() - time.getTime()) / 1000);
    if (differenceInSeconds < 30) {
      if(this.is_open_popup == false){
        this.show_sos_alert(updateTime)
      }
    }
  }

}
