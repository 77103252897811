import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '../shared/helper';
import { LangService } from '../shared/lang.service';

@Pipe({
    name: 'langpipe',    
})
export class LangPipe implements PipeTransform {

    constructor(private _lang:LangService){}

    transform(value: any): any {
        try{
            if(typeof value === 'object'){
                return value[this._lang.selectedlanguageIndex] || value[0] || 'N/A';
            }else{
                return value;
            }
        }catch(err){
            return value;
        }
    }
}