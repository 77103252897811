export var ORDER_STATE = {
    WAITING_FOR_ACCEPT_STORE: 1,
    CANCELED_BY_USER: 101,
    STORE_ACCEPTED: 3,
    STORE_REJECTED: 103,
    STORE_CANCELLED: 104,
    STORE_CANCELLED_REQUEST: 105,
    STORE_PREPARING_ORDER: 5,
    OREDER_READY: 7,
    WAITING_FOR_DELIVERY_MAN: 9,
    NO_DELIVERY_MAN_FOUND: 109,
    DELIVERY_MAN_ACCEPTED: 11,
    DELIVERY_MAN_REJECTED: 111,
    DELIVERY_MAN_CANCELLED: 112,
    DELIVERY_MAN_COMING: 13,
    DELIVERY_MAN_ARRIVED: 15,
    DELIVERY_MAN_PICKED_ORDER: 17,
    DELIVERY_MAN_STARTED_DELIVERY: 19,
    DELIVERY_MAN_ARRIVED_AT_DESTINATION: 21,
    DELIVERY_MAN_COMPLETE_DELIVERY: 23,
    ORDER_COMPLETED: 25,
};

export var DOCUMENT_FOR = [
    {VALUE: 2, TITLE: 'Store'},
    {VALUE: 7, TITLE: 'User'},
    {VALUE: 8, TITLE: 'Delivery Man'},
    {VALUE: 9, TITLE: 'Delivery Man Vehicle'}
]

export var ADMIN_PROFIT_ON_ORDER = [
    { ID: 1, NAME: "PERCENTAGE" },
    { ID: 2, NAME: "ABSOLUTE PRICE PER ORDER" },
    { ID: 3, NAME: "ABSOLUTE PRICE PER ITEMS" }
];


export var DEFAULT_IMAGE_PATH = {
    PROMO_CODE: '/assets/img/default_images/promo_code.png',
    USER: '/assets/img/default_images/user.png',
    CATEGORY: '/assets/img/default_images/category.png',
    DRIVER: '/assets/img/default_images/driver.png',
    DOCUMENT: '/assets/img/default_images/document_default.png',
    PROMO: '/assets/img/default_images/promo_code.png',
    DELIVERY: '/assets/img/default_images/delivery/food.jpg',
    DELIVERY_ICON: '/assets/img/default_images/delivery/food_icon.png',
    LANDING_IMAGE: '/assets/img/default_images/popup_side_img_admin.png',
    ADVERTISE: '/assets/img/default_images/ads_default_square.png',
    PATIENT: '/assets/img/Avatar 7.png',
}

export var USER_TYPE = {
    USER: 7,
    PROVIDER: 8,
    STORE: 2
}

export var WALLET_REQUEST_STATUS = {
    CREATED: 1,
    ACCEPTED: 2,
    TRANSFERED: 3,
    COMPLETED: 4,
    CANCELLED: 5
};

export var DELIVERY_TYPE = [
    { value: 1, title: 'Store'},
    { value: 2, title: 'Courier'}
]

export var ADMIN_PROFIT_ON_DELIVERY_STRING = {
    PERCENTAGE: "Percentage",
    PER_DELVIERY: "Absolute price per delivery"
};

export var ADMIN_PROFIT_ON_DELIVERY_ID = {
    PERCENTAGE: 1,
    PER_DELVIERY: 2
};

export var ADMIN_PROFIT_ON_DELIVERYS = [
    {ID: ADMIN_PROFIT_ON_DELIVERY_ID.PERCENTAGE, NAME: ADMIN_PROFIT_ON_DELIVERY_STRING.PERCENTAGE},
    {ID: ADMIN_PROFIT_ON_DELIVERY_ID.PER_DELVIERY, NAME: ADMIN_PROFIT_ON_DELIVERY_STRING.PER_DELVIERY}

];

export var DATE_FORMAT = {
    DD_MM_YYYY_HH_MM_A: 'dd MMM yyyy hh:mm a',
    DD_MM_YYYY_HH_MM_SS_A: 'dd MMM yyyy hh:mm:ss a',
    DD_MM_YYYY_HH_MM_SS_MM_A: 'dd MMM yyyy hh:mm:ss:mm a',
    DD_MM_YYYY_HH_MM_SS_MM: 'dd MMM yyyy HH:mm:ss:mm',
    DD_MM_YYYY: 'dd MMM yyyy',
    DD_MM_YYYY_HH_MM_SS:'dd MMM yyyy HH:mm:ss'
}

export var PROMO_FOR_ID = {
    SERVICE: 20,
    DELIVERIES: 0,
    STORE: 2,
    PRODUCT: 21,
    ITEM: 22
};

export var PROMO_FOR_STRING = {
    DELIVERIES: "Deliveries",
    STORE: "Store",
    PRODUCT: "Product",
    ITEM: "Item",
    SERVICE: 'Service'
};

export var PROMO_FOR = [
    {ID: PROMO_FOR_ID.STORE, NAME: PROMO_FOR_STRING.STORE},
    {ID: PROMO_FOR_ID.PRODUCT, NAME: PROMO_FOR_STRING.PRODUCT},
    {ID: PROMO_FOR_ID.ITEM, NAME: PROMO_FOR_STRING.ITEM}
];

export var MONTH = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
]

export var WEEK = [
    'First',
    'Second',
    'Third',
    'Fourth',
    'Fifth'
]

export var DAY = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
]

export var PROMO_RECURSION_ID = {
    NO_RECURSION: 0,
    DAILY_RECURSION: 1,
    WEEKLY_RECURSION: 2,
    MONTHLY_RECURSION: 3,
    ANNUALLY_RECURSION: 4
};
export var PROMO_RECURSION_STRING = {
    NO_RECURSION: "No Recursion",
    DAILY_RECURSION: "Daily Recursion",
    WEEKLY_RECURSION: "Weekly Recursion",
    MONTHLY_RECURSION: "Monthly Recursion",
    ANNUALLY_RECURSION: "Annually Recursion"
};

export var PROMO_RECURSION = [
    {ID: PROMO_RECURSION_ID.NO_RECURSION, NAME: PROMO_RECURSION_STRING.NO_RECURSION},
    {ID: PROMO_RECURSION_ID.DAILY_RECURSION, NAME: PROMO_RECURSION_STRING.DAILY_RECURSION},
    {ID: PROMO_RECURSION_ID.WEEKLY_RECURSION, NAME: PROMO_RECURSION_STRING.WEEKLY_RECURSION},
    {ID: PROMO_RECURSION_ID.MONTHLY_RECURSION, NAME: PROMO_RECURSION_STRING.MONTHLY_RECURSION},
    {ID: PROMO_RECURSION_ID.ANNUALLY_RECURSION, NAME: PROMO_RECURSION_STRING.ANNUALLY_RECURSION}
];


export var ADMIN_PROMO_FOR_ID = {
    DELIVERIES: 0,
    STORE: 2,
    SERVICE: 20
};

export var ADMIN_PROMO_FOR_STRING = {
    DELIVERIES: "Deliveries",
    STORE: "Store",
    SERVICE: "Service",
    PRODUCT: "Product",
    ITEM: "Item",

};
// CONSTANT ARRAY
export var ADMIN_PROMO_FOR = [
    {ID: ADMIN_PROMO_FOR_ID.DELIVERIES, NAME: ADMIN_PROMO_FOR_STRING.DELIVERIES},
    {ID: ADMIN_PROMO_FOR_ID.STORE, NAME: ADMIN_PROMO_FOR_STRING.STORE},
    {ID: ADMIN_PROMO_FOR_ID.SERVICE, NAME: ADMIN_PROMO_FOR_STRING.SERVICE}
];

export var CHARGE_TYPE = {
    PERCENTAGE: 1,
    ABSOLUTE: 2
};


export var PER_PAGE_LIST = [5,10,15]

export var ADMIN_URL_ID = {
    DASHBOARD: "/admin/dashboard",
    USER: "/admin/users",
    PROVIDER: "/admin/providers",
    STORES: "/admin/stores",
    STORE_LOCATION: "/admin/store_location",
    PROVIDER_LOCATION: "/admin/provider_location",
    PROVIDER_TRACK: "/admin/location_track",
    DELIVERY: "/admin/delivery",
    VEHICLE: "/admin/vehicle",
    COUNTRY: "/admin/country",
    CITY: "/admin/city",
    DELIVERIES_PRICES: "/admin/service",
    ORDERS: "/admin/orders",
    DELIVERIES: "/admin/deliveries",
    HISTORY: "/admin/history",
    ORDER_EARNING: "/admin/order_earning",
    PROVIDER_WEEKLY_EARNING: "/admin/provider_weekly_earning",
    STORE_WEEKLY_EARNING: "/admin/store_weekly_earning",
    ADMIN_SETTINGS: "/setting/basic_setting",
    PROMO_CODE: "/admin/promotions",
    REFERRAL_DETAIL: "/admin/referral_detail",
    WALLET_HISTORY: "/admin/wallet_detail",
    WALLET_REQUEST: "/admin/wallet_request",
    TRANSACTION_HISTORY: "/admin/transaction_history",
    ADS: "/admin/advertise",
    DOCUMENTS: "/admin/document",
    MAIL: "/admin/email",
    SMS: "/admin/sms"
};

export var ADMIN_URL_STRING = {
    DASHBOARD: "Dashboard",
    USER: "User",
    PROVIDER: 'Deliveryman',
    STORES: "Store",
    STORE_LOCATION: "Store Location",
    PROVIDER_LOCATION: "Deliveryman Location",
    PROVIDER_TRACK: "Deliveryman Track",
    DELIVERY: "Delivery",
    VEHICLE: "Vehicle",
    COUNTRY: "Country",
    CITY: "City",
    DELIVERIES_PRICES: "Deliveries Prices",
    ORDERS: "Orders",
    DELIVERIES: "Deliveries",
    HISTORY: "History",
    ORDER_EARNING: "Order Earning",
    PROVIDER_WEEKLY_EARNING: "Deliveryman Weekly Earning",
    STORE_WEEKLY_EARNING: "Store Weekly Earning",
    ADMIN_SETTINGS: "Admin Settings",
    PROMO_CODE: "Promo Code",
    REFERRAL_DETAIL: "Referral Detail",
    WALLET_HISTORY: "Wallet History",
    WALLET_REQUEST: "Wallet Request",
    TRANSACTION_HISTORY: "Transaction History",
    ADS: "Ads",
    DOCUMENTS: "Documents",
    MAIL: "Mail",
    SMS: "SMS",
};

export var ADMIN_URL = [
    // {ID: ADMIN_URL_ID.DASHBOARD, NAME: ADMIN_URL_STRING.DASHBOARD},
    {ID: ADMIN_URL_ID.USER, NAME: ADMIN_URL_STRING.USER},
    {ID: ADMIN_URL_ID.STORES, NAME: ADMIN_URL_STRING.STORES},
    {ID: ADMIN_URL_ID.PROVIDER, NAME: ADMIN_URL_STRING.PROVIDER},
    {ID: ADMIN_URL_ID.STORE_LOCATION, NAME: ADMIN_URL_STRING.STORE_LOCATION},
    {ID: ADMIN_URL_ID.PROVIDER_LOCATION, NAME: ADMIN_URL_STRING.PROVIDER_LOCATION},
    {ID: ADMIN_URL_ID.PROVIDER_TRACK, NAME: ADMIN_URL_STRING.PROVIDER_TRACK},
    {ID: ADMIN_URL_ID.DELIVERY, NAME: ADMIN_URL_STRING.DELIVERY},
    {ID: ADMIN_URL_ID.VEHICLE, NAME: ADMIN_URL_STRING.VEHICLE},
    {ID: ADMIN_URL_ID.COUNTRY, NAME: ADMIN_URL_STRING.COUNTRY},
    {ID: ADMIN_URL_ID.CITY, NAME: ADMIN_URL_STRING.CITY},
    {ID: ADMIN_URL_ID.DELIVERIES_PRICES, NAME: ADMIN_URL_STRING.DELIVERIES_PRICES},
    {ID: ADMIN_URL_ID.TRANSACTION_HISTORY, NAME: ADMIN_URL_STRING.TRANSACTION_HISTORY},
    {ID: ADMIN_URL_ID.ORDERS, NAME: ADMIN_URL_STRING.ORDERS},
    {ID: ADMIN_URL_ID.DELIVERIES, NAME: ADMIN_URL_STRING.DELIVERIES},
    {ID: ADMIN_URL_ID.HISTORY, NAME: ADMIN_URL_STRING.HISTORY},
    {ID: ADMIN_URL_ID.ORDER_EARNING, NAME: ADMIN_URL_STRING.ORDER_EARNING},
    {ID: ADMIN_URL_ID.PROVIDER_WEEKLY_EARNING, NAME: ADMIN_URL_STRING.PROVIDER_WEEKLY_EARNING},
    {ID: ADMIN_URL_ID.STORE_WEEKLY_EARNING, NAME: ADMIN_URL_STRING.STORE_WEEKLY_EARNING},
    {ID: ADMIN_URL_ID.ADMIN_SETTINGS, NAME: ADMIN_URL_STRING.ADMIN_SETTINGS},
    {ID: ADMIN_URL_ID.PROMO_CODE, NAME: ADMIN_URL_STRING.PROMO_CODE},
    {ID: ADMIN_URL_ID.REFERRAL_DETAIL, NAME: ADMIN_URL_STRING.REFERRAL_DETAIL},
    {ID: ADMIN_URL_ID.WALLET_HISTORY, NAME: ADMIN_URL_STRING.WALLET_HISTORY},
    {ID: ADMIN_URL_ID.WALLET_REQUEST, NAME: ADMIN_URL_STRING.WALLET_REQUEST},
    {ID: ADMIN_URL_ID.ADS, NAME: ADMIN_URL_STRING.ADS},
    {ID: ADMIN_URL_ID.DOCUMENTS, NAME: ADMIN_URL_STRING.DOCUMENTS},
    {ID: ADMIN_URL_ID.MAIL, NAME: ADMIN_URL_STRING.MAIL},
    {ID: ADMIN_URL_ID.SMS, NAME: ADMIN_URL_STRING.SMS}
];


export var ADMIN_ROLE_URL_ID = {

    USER: "/admin/users",
    PATIENT_LIST: "/admin/patient-list",
    GROUP: "/admin/group",
    SUB_ADMIN: "/admin/sub-admin",
    HOSPITAL_USER_LIST: "/admin/hospital-user-list",

    PROVINCE_LIST: "/master_data/province-list",
    DISTRICT_LIST: "/master_data/district-list",
    SUB_DISTRICT_LIST: "/master_data/subdistrict-list",
    HOSPITAL_LIST: "/master_data/hospital-list",
    NATIONALITY_LIST: "/master_data/nationality-list",
    HUMAN_SKIN_COLOR_LIST: "/master_data/human-skin-color-list",
    BLOOD_GROUP_LIST: "/master_data/blood-group-list",

    ADMIN: "/setting/admin",
    ADMIN_ROLES: "/setting/admin-roles",
    LANGUAGE: "/setting/language",
    TERMS_SETTINGS: "/setting/terms-settings",

    REQUEST_AMBULANCES_FORM: "/forms/request-ambulances-form",
    TRANSER_RECORDING_FOR_AMBULANCE: "/forms/transfer-recording-for-ambulance",
    TRANSPORTATION_TREATEMENT: "/forms/transporatation-treatement",
    OPERATION_FORM: "/forms/operation-form",

    ACTIVITY_LOGS: "/activity_logs/activity-log",
    VOIP_USER_LIST: "/master_data/voip-user-list",
    STAFF_USER_LIST: "/master_data/staff-type-list",
    UNDERLYING_DISEASE_LIST: "/master_data/underlying-disease-list",
    AVA_BOX_LIST: "/master_data/ava-box-list",
    WATCH_LIST: "/master_data/watch-list",
    MEDICAL_DEVICES_LIST: "/master_data/medical-devices-list",
    MOBILE_OPERATOR_LIST: "/master_data/mobile-operator-list",
    WARD_LIST: "/master_data/ward-list",

    AMY_BOX_LIST: "/amy_ava/amy-box-list",
    AMY_DEVICE_LIST: "/amy_ava/amy-device-list",
    AMY_BOX_MONITORING: "/amy_ava/amy-box-monitoring",
    AMY_BOX_TRANSACTION: "/amy_ava/amy-box-transaction",
    AMY_SENSOR_LIST: "/amy_ava/amy-sensor-list",
    AMY_SENSOR_TRANSACTION: "/amy_ava/amy-sensor-transaction",
    ESP32_TRANSACTION: "/amy_ava/esp32-transaction",
    MFC_TRANSACTION: "/amy_ava/mfc-transaction",
    MFC_HV01_BOX: "/amy_ava/mfc-HV01-box",

    AMY_WATCH_DEBUG_TRANSACTION: "/amy_watch/amy-watch-debug-transaction",
    AMY_WATCH_TRANSACTION: "/amy_watch/amy-watch-transaction",
    WATCH_MANAGEMENT: "/amy_watch/watch-management",
    WATCH_USER_LOCATION: "/amy_watch/watch-user-location",
    WATCH_PUSH_NOTIFICATION: "/amy_watch/watch-push-notification",
    WATCH_LOCATION_TRANSACTION: "/amy_watch/watch-location-transaction",
    TCP_WATCH_DEBUG_TRANSACTION: "/amy_watch/tcp-watch-debug-transaction",
    AMY_WATCH_MONITORING: "/amy_watch/amy-watch-monitoring",
    WATCH_PATIENT_LOCATION: "/admin/patient-detail",

    NOTIFY_TRANSACTION: "/notification/notify-transaction",

};

export var ADMIN_ROLE_URL_STRING = {
    USER: "User",
    PATIENT_LIST: "Patient List",
    GROUP: "Group",
    SUB_ADMIN: "Sub Admin",
    HOSPITAL_USER_LIST : "Hospital User List",

    PROVINCE_LIST: "Province List",
    DISTRICT_LIST: "District List",
    SUB_DISTRICT_LIST: "Sub District List",
    HOSPITAL_LIST: "Hospital List",
    NATIONALITY_LIST: "Nationality List",
    HUMAN_SKIN_COLOR_LIST: "Human Skin Color List",
    BLOOD_GROUP_LIST: "Blood Group List",

    ADMIN: "Admin",
    ADMIN_ROLES: "Admin Roles",
    LANGUAGE: "Language",
    TERMS_SETTINGS: "Privacy Policy",

    ACTIVITY_LOGS: "Activity Logs",
    REQUEST_AMBULANCES_FORM: "Request Ambulances Form",
    TRANSER_RECORDING_FOR_AMBULANCE: "Transfer Recording For Ambulance",
    TRANSPORTATION_TREATEMENT: "Transporatation Treatement",
    OPERATION_FORM: "Operation Form",
    VOIP_USER_LIST: "VoIP User List",
    STAFF_USER_LIST:"Staff Type List",
    UNDERLYING_DISEASE_LIST: "Underlying Disease List",
    AVA_BOX_LIST: "AVA Box List",
    WATCH_LIST:"Watch List",
    MEDICAL_DEVICES_LIST: "Medical Devices List",
    MOBILE_OPERATOR_LIST: "Mobile Operator List",
    WARD_LIST : "Ward List",

    AMY_BOX_LIST: "AMY Box List",
    AMY_DEVICE_LIST: "AMY Device List",
    AMY_BOX_MONITORING: "AMY Box Monitoring",
    AMY_BOX_TRANSACTION: "AMY Box Transaction",
    AMY_SENSOR_LIST: "Sensor List",
    AMY_SENSOR_TRANSACTION: "Sensor Transaction",
    ESP32_TRANSACTION : "MFC-AVA3 Transaction",
    MFC_TRANSACTION : "MFC-HV01 Transaction",
    NOTIFY_TRANSACTION : "Notify Transaction",
    MFC_HV01_BOX: "MFC-HV01 Box",


    AMY_WATCH_DEBUG_TRANSACTION: "AMY Watch Debug Transaction",
    AMY_WATCH_TRANSACTION: "AMY Watch Transaction",

    WATCH_MANAGEMENT: "Watch Management",
    WATCH_USER_LOCATION: "Watch User Location",
    WATCH_PUSH_NOTIFICATION: "Watch Push Notification",
    WATCH_LOCATION_TRANSACTION: "Location Transaction",
    TCP_WATCH_DEBUG_TRANSACTION: "DT-69 Debug Transactions",
    AMY_WATCH_MONITORING: "Watch Monitoring",
    WATCH_PATIENT_LOCATION: "User Map location access",
};

export var ADMIN_ROLE_URL = [

    {ID: ADMIN_ROLE_URL_ID.USER, NAME: ADMIN_ROLE_URL_STRING.USER},
    {ID: ADMIN_ROLE_URL_ID.PATIENT_LIST, NAME: ADMIN_ROLE_URL_STRING.PATIENT_LIST},
    {ID: ADMIN_ROLE_URL_ID.GROUP, NAME: ADMIN_ROLE_URL_STRING.GROUP},
    {ID: ADMIN_ROLE_URL_ID.SUB_ADMIN, NAME: ADMIN_ROLE_URL_STRING.SUB_ADMIN},
    {ID: ADMIN_ROLE_URL_ID.HOSPITAL_USER_LIST, NAME: ADMIN_ROLE_URL_STRING.HOSPITAL_USER_LIST},

    {ID: ADMIN_ROLE_URL_ID.PROVINCE_LIST, NAME: ADMIN_ROLE_URL_STRING.PROVINCE_LIST},
    {ID: ADMIN_ROLE_URL_ID.DISTRICT_LIST, NAME: ADMIN_ROLE_URL_STRING.DISTRICT_LIST},
    {ID: ADMIN_ROLE_URL_ID.SUB_DISTRICT_LIST, NAME: ADMIN_ROLE_URL_STRING.SUB_DISTRICT_LIST},
    {ID: ADMIN_ROLE_URL_ID.HOSPITAL_LIST, NAME: ADMIN_ROLE_URL_STRING.HOSPITAL_LIST},
    {ID: ADMIN_ROLE_URL_ID.NATIONALITY_LIST, NAME: ADMIN_ROLE_URL_STRING.NATIONALITY_LIST},
    {ID: ADMIN_ROLE_URL_ID.HUMAN_SKIN_COLOR_LIST, NAME: ADMIN_ROLE_URL_STRING.HUMAN_SKIN_COLOR_LIST},
    {ID: ADMIN_ROLE_URL_ID.BLOOD_GROUP_LIST, NAME: ADMIN_ROLE_URL_STRING.BLOOD_GROUP_LIST},

    {ID: ADMIN_ROLE_URL_ID.ADMIN, NAME: ADMIN_ROLE_URL_STRING.ADMIN},
    {ID: ADMIN_ROLE_URL_ID.ADMIN_ROLES, NAME: ADMIN_ROLE_URL_STRING.ADMIN_ROLES},
    {ID: ADMIN_ROLE_URL_ID.LANGUAGE, NAME: ADMIN_ROLE_URL_STRING.LANGUAGE},
    {ID: ADMIN_ROLE_URL_ID.TERMS_SETTINGS, NAME: ADMIN_ROLE_URL_STRING.TERMS_SETTINGS},

    {ID: ADMIN_ROLE_URL_ID.ACTIVITY_LOGS, NAME: ADMIN_ROLE_URL_STRING.ACTIVITY_LOGS},
    {ID: ADMIN_ROLE_URL_ID.VOIP_USER_LIST, NAME: ADMIN_ROLE_URL_STRING.VOIP_USER_LIST},
    {ID: ADMIN_ROLE_URL_ID.STAFF_USER_LIST, NAME: ADMIN_ROLE_URL_STRING.STAFF_USER_LIST},
    {ID: ADMIN_ROLE_URL_ID.UNDERLYING_DISEASE_LIST, NAME: ADMIN_ROLE_URL_STRING.UNDERLYING_DISEASE_LIST},
    {ID: ADMIN_ROLE_URL_ID.AVA_BOX_LIST, NAME: ADMIN_ROLE_URL_STRING.AVA_BOX_LIST},
    {ID: ADMIN_ROLE_URL_ID.WATCH_LIST, NAME: ADMIN_ROLE_URL_STRING.WATCH_LIST},
    {ID: ADMIN_ROLE_URL_ID.MEDICAL_DEVICES_LIST, NAME: ADMIN_ROLE_URL_STRING.MEDICAL_DEVICES_LIST},
    {ID: ADMIN_ROLE_URL_ID.MOBILE_OPERATOR_LIST, NAME: ADMIN_ROLE_URL_STRING.MOBILE_OPERATOR_LIST},
    {ID: ADMIN_ROLE_URL_ID.WARD_LIST, NAME: ADMIN_ROLE_URL_STRING.WARD_LIST},

    {ID: ADMIN_ROLE_URL_ID.REQUEST_AMBULANCES_FORM, NAME: ADMIN_ROLE_URL_STRING.REQUEST_AMBULANCES_FORM},
    {ID: ADMIN_ROLE_URL_ID.TRANSER_RECORDING_FOR_AMBULANCE, NAME: ADMIN_ROLE_URL_STRING.TRANSER_RECORDING_FOR_AMBULANCE},
    {ID: ADMIN_ROLE_URL_ID.TRANSPORTATION_TREATEMENT, NAME: ADMIN_ROLE_URL_STRING.TRANSPORTATION_TREATEMENT},
    {ID: ADMIN_ROLE_URL_ID.OPERATION_FORM, NAME: ADMIN_ROLE_URL_STRING.OPERATION_FORM},

    {ID: ADMIN_ROLE_URL_ID.AMY_BOX_LIST, NAME: ADMIN_ROLE_URL_STRING.AMY_BOX_LIST},
    {ID: ADMIN_ROLE_URL_ID.AMY_DEVICE_LIST, NAME: ADMIN_ROLE_URL_STRING.AMY_DEVICE_LIST},
    {ID: ADMIN_ROLE_URL_ID.AMY_BOX_MONITORING, NAME: ADMIN_ROLE_URL_STRING.AMY_BOX_MONITORING},
    {ID: ADMIN_ROLE_URL_ID.AMY_BOX_TRANSACTION, NAME: ADMIN_ROLE_URL_STRING.AMY_BOX_TRANSACTION},
    {ID: ADMIN_ROLE_URL_ID.AMY_SENSOR_LIST, NAME: ADMIN_ROLE_URL_STRING.AMY_SENSOR_LIST},
    {ID: ADMIN_ROLE_URL_ID.AMY_SENSOR_TRANSACTION, NAME: ADMIN_ROLE_URL_STRING.AMY_SENSOR_TRANSACTION},
    {ID: ADMIN_ROLE_URL_ID.ESP32_TRANSACTION, NAME: ADMIN_ROLE_URL_STRING.ESP32_TRANSACTION},
    {ID: ADMIN_ROLE_URL_ID.MFC_TRANSACTION, NAME: ADMIN_ROLE_URL_STRING.MFC_TRANSACTION},
    {ID: ADMIN_ROLE_URL_ID.NOTIFY_TRANSACTION, NAME: ADMIN_ROLE_URL_STRING.NOTIFY_TRANSACTION},
    {ID: ADMIN_ROLE_URL_ID.MFC_HV01_BOX, NAME: ADMIN_ROLE_URL_STRING.MFC_HV01_BOX},


    {ID: ADMIN_ROLE_URL_ID.AMY_WATCH_DEBUG_TRANSACTION, NAME: ADMIN_ROLE_URL_STRING.AMY_WATCH_DEBUG_TRANSACTION},
    {ID: ADMIN_ROLE_URL_ID.AMY_WATCH_TRANSACTION, NAME: ADMIN_ROLE_URL_STRING.AMY_WATCH_TRANSACTION},

    {ID: ADMIN_ROLE_URL_ID.WATCH_MANAGEMENT, NAME: ADMIN_ROLE_URL_STRING.WATCH_MANAGEMENT},
    {ID: ADMIN_ROLE_URL_ID.WATCH_USER_LOCATION, NAME: ADMIN_ROLE_URL_STRING.WATCH_USER_LOCATION},

    {ID: ADMIN_ROLE_URL_ID.WATCH_PUSH_NOTIFICATION, NAME: ADMIN_ROLE_URL_STRING.WATCH_PUSH_NOTIFICATION},
    {ID: ADMIN_ROLE_URL_ID.WATCH_LOCATION_TRANSACTION, NAME: ADMIN_ROLE_URL_STRING.WATCH_LOCATION_TRANSACTION},
    {ID: ADMIN_ROLE_URL_ID.TCP_WATCH_DEBUG_TRANSACTION, NAME: ADMIN_ROLE_URL_STRING.TCP_WATCH_DEBUG_TRANSACTION},
    {ID: ADMIN_ROLE_URL_ID.AMY_WATCH_MONITORING, NAME: ADMIN_ROLE_URL_STRING.AMY_WATCH_MONITORING},

    {ID: ADMIN_ROLE_URL_ID.WATCH_PATIENT_LOCATION, NAME: ADMIN_ROLE_URL_STRING.WATCH_PATIENT_LOCATION},

];


export var ADMIN_DATA_ID = {
    ADMIN: 1,
    SUB_ADMIN: 3,
    STORE: 2,
    USER: 7,
    PROVIDER: 8,
    PROVIDER_VEHICLE: 9
};

export var HEALTH_DEVICE_TYPE = {
    BODY_TEMPERATURE : 0,
    SPO2 : 1,
    BLOOD_PRESSURE : 2,
    DUSUN_BODY_TEMPERATURE : 3,
    DUSUN_SPO2 : 4,
    DUSUN_BPS : 5,

    MGSS_REF_GLUC: 6,
    MGSS_REF_CHOL: 7,
    MGSS_REF_UA: 8,
    DUSUN_WEIGHT_SCALE: 9,
    BLE_connected: 10,
    BLE_disconnected: 11,
    ACCUCHEK_INSTANT: 12,
    CONTOUR_ELITE: 13,
    BODYSCALE_JUMPER: 14,
    IR_TEMO_JUMPER : 15

};

export var WALLET_COMMENT_ID = {
    SET_BY_ADMIN: 1,
    ADDED_BY_CARD: 2,
    ADDED_BY_REFERRAL: 3,
    ORDER_CHARGED: 4,
    ORDER_REFUND: 5,
    SET_ORDER_PROFIT: 6,
    ORDER_CANCELLATION_CHARGE: 7,
    SET_BY_WALLET_REQUEST: 8,

    SET_WEEKLY_PAYMENT_BY_ADMIN: 9,
};
