import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from '../views/error/error.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { UnauthorizedComponent } from '../views/unauthorized/unauthorized.component';
import { BreadcrumbComponent } from '../containers/layout/breadcrumb/breadcrumb.component';
import { HeadingComponent } from '../containers/layout/heading/heading.component';
@NgModule({
  declarations: [ErrorComponent, UnauthorizedComponent,BreadcrumbComponent,HeadingComponent],
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    PerfectScrollbarModule
  ],
  exports: [
    PerfectScrollbarModule,
    RouterModule,
    ErrorComponent,
    UnauthorizedComponent,
    TranslateModule,
    CommonModule,
    BreadcrumbComponent,
    HeadingComponent
  ],
})
export class SharedModule {}
