<style>

.border-none {
  border: none !important;
}

.border-red {
  border: 4px solid #EC1C24 !important;
}

.border-pink {
  border: 4px solid #FF4CCD !important;
}

.border-yellow {
  border: 4px solid #FAC13C !important;
}

.border-green {
  border: 4px solid #1A982E !important;
}

.border-white {
  border: 4px solid #D9D9D9 !important;
}

  .profile-picture {
    height: 50%;
    max-height: 35px;
  }
</style>
<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"
      href="javascript:;" class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg> {{" "}}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>

    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>

    <!-- <div class="search" (click)="searchAreaClick($event)">
      <input placeholder="Search..." [(ngModel)]="searchKey" (keyup)="searchKeyUp($event)">
      <span class="search-icon" (click)="searchClick($event)">
        <i class="simple-icon-magnifier"></i>
      </span>
    </div> -->

    <div class="btn-group ml-2" dropdown *ngIf="!isSingleLang">
      <button dropdownToggle type="button" class="language-button dropdown-toggle btn btn-light btn-sm">
        {{currentLanguage | uppercase}} <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem" *ngFor="let item of languages" (click)="onLanguageChange(item)"><a class="dropdown-item"
            href="javascript:;">{{item.label}}</a></li>
      </ul>
    </div>

    <!-- <a class="btn btn-sm btn-outline-primary ml-2 d-none d-xl-inline-block" [href]="buyUrl"
      target="_blank">&nbsp;BUY&nbsp;</a> -->
  </div>

  <a class="navbar-logo d-flex flex-row justify-content-center" [routerLink]="adminRoot">
    <!-- <span class="logo d-none d-xs-block"></span>
    <span class="logo-mobile d-block d-xs-none"></span> -->
    <img alt="Profile Picture" class="profile-picture"
      [src]="helper.image_url+'web_images/logo.png'" />&nbsp;&nbsp;&nbsp;&nbsp;<span>
      <h1>{{appName}}</h1>
    </span>
  </a>

  <div class="navbar-right">
    <div class="header-icons d-inline-block align-middle">

      <div class="d-none d-md-inline-block align-text-bottom mr-1">
        <div class="custom-control custom-switch" tooltip="Dark Mode" placement="left">
          <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive"
            (change)="onDarkModeChange($event)">
          <label class="custom-control-label" for="darkModeSwitch"></label>
        </div>
      </div>

      <!-- <div class="position-relative d-none d-sm-inline-block">
        <div dropdown>
          <button dropdownToggle type="button" class="header-icon btn btn-empty">
            <i class="simple-icon-grid"></i>
          </button>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3" role="menu" aria-labelledby="button-basic"
            id="iconMenuDropdown">
            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-equalizer d-block"></i>
              <span>Settings</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-male-female d-block"></i>
              <span>Users</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-puzzle d-block"></i>
              <span>Components</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-bar-chart-4 d-block"></i>
              <span>Profits</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-file d-block"></i>
              <span>Surveys</span>
            </a>

            <a href="javascript:;" class="icon-menu-item">
              <i class="iconsminds-suitcase d-block"></i>
              <span>Tasks</span>
            </a>
          </div>
        </div>
      </div> -->

      <div class="position-relative d-inline-block">

        <div dropdown>
          <button id="button-basic" *ngIf="notifications.length == 0" type="button" id="notificationButton"
            class="header-icon btn btn-empty">
            <i class="simple-icon-bell"></i>
          </button>

          <button id="button-basic" *ngIf="notifications.length > 0" dropdownToggle type="button"
            id="notificationButton" class="header-icon btn btn-empty">
            <i class="simple-icon-bell"></i>
            <span class="count">{{notifications.length}}</span>
          </button>

          <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3"
            id="notificationDropdown" style="width: 550px;height: auto;max-height: 50vh;overflow: auto;">
            <perfect-scrollbar>
              <div *ngFor="let data of notifications; let i=index" (click)="show_sos_alert(data)"
                class="d-flex flex-row mb-3 pb-3 border-bottom">
                <a href="javascript:;" style="width: 50px;border-radius: 5rem;">
                  <img
                    class="img-fluid border-radius mb-1 border mr-2 img-thumbnail list-thumbnail xsmall border-0 rounded-circle"
                    [ngClass]="{
                      'border-none': !data?.patient_detail?.TriageLevel_info || data?.patient_detail?.TriageLevel_info === 0,
                      'border-red': data?.patient_detail?.TriageLevel_info?.length > 0 && data?.patient_detail?.TriageLevel_info[data?.patient_detail?.TriageLevel_info?.length - 1]?.currentTriageLevel === 'RED',
                      'border-pink': data?.patient_detail?.TriageLevel_info?.length > 0 && data?.patient_detail?.TriageLevel_info[data?.patient_detail?.TriageLevel_info?.length - 1]?.currentTriageLevel === 'PINK',
                      'border-yellow': data?.patient_detail?.TriageLevel_info?.length > 0 && data?.patient_detail?.TriageLevel_info[data?.patient_detail?.TriageLevel_info?.length - 1]?.currentTriageLevel === 'YELLOW',
                      'border-green': data?.patient_detail?.TriageLevel_info?.length > 0 && data?.patient_detail?.TriageLevel_info[data?.patient_detail?.TriageLevel_info?.length - 1]?.currentTriageLevel === 'GREEN',
                      'border-white': data?.patient_detail?.TriageLevel_info?.length > 0 && data?.patient_detail?.TriageLevel_info[data?.patient_detail?.TriageLevel_info?.length - 1]?.currentTriageLevel === 'WHITE'
                    }"
                    [src]="data?.patient_detail?.profile_image ? (helper.image_url + data?.patient_detail?.profile_image):(helper.image_url+'default.png')">
                </a>

                <div class="pl-3">
                  <a href="javascript:;">
                    <p class="font-weight-medium mb-1">{{data?.notify_message}}</p>

                    <p class="font-weight-medium mb-1"><b>Patient : </b>{{data?.patient_detail?.name_title}}
                      {{data?.patient_detail?.first_name }} {{data?.patient_detail?.last_name }} <br></p>
                    <p class="font-weight-medium mb-1"><b>Mobile : </b> {{data?.patient_detail?.mobile_no }}<br></p>
                    <p class="font-weight-medium mb-1"><b>Message : </b> {{data?.notify_message}}<br></p>
                    <p class="font-weight-medium mb-1"><b>Device : </b>{{data?.attribute}} {{data?.ble_address}}<br></p>
                    <p class="font-weight-medium mb-1"><b>Gateway : </b> {{data?.box_mac_address}}
                      {{data?.box_name}}<br></p>
                    <p class="font-weight-medium mb-1"><b>Time stamp : </b> {{data?.updated_at |
                      date:helper.DATE_FORMAT.DD_MM_YYYY_HH_MM_SS_MM}}<br></p>

                    <!-- <span *ngIf="data?.admin_read_detail?._id" ><b>Acknowledge By : </b> {{data?.admin_read_detail?.username}} (ADMIN) <br></span>
                    <span *ngIf="data?.read_admin_at" ><b>Acknowledge Time : </b> {{datepipe.transform(data?.read_admin_at, 'dd/MM/yyyy, hh:mm:ss:mm a')}}<br></span> -->
<!--
                    <span *ngIf="data?.user_read_detail?._id" ><b>Acknowledge By : </b> {{data?.user_read_detail?.user_title}} {{data?.user_read_detail?.first_name}} {{data?.user_read_detail?.last_name}} (USER) <br></span>
                    <span *ngIf="data?.read_user_at" ><b>Acknowledge Time : </b> {{datepipe.transform(data?.read_user_at, 'dd/MM/yyyy, hh:mm:ss:mm a')}}<br></span> -->

                    <!-- <span *ngIf="data?.hospital_read_detail?._id" ><b>Acknowledge By : </b> {{data?.hospital_read_detail?.user_title}} {{data?.hospital_read_detail?.first_name}} {{data?.hospital_read_detail?.last_name}} (HOSPITAL USER) <br></span>
                    <span *ngIf="data?.read_hospital_user_at" ><b>Acknowledge Time : </b> {{datepipe.transform(data?.read_hospital_user_at, 'dd/MM/yyyy, hh:mm:ss:mm a')}}<br></span>   -->

                    <span  *ngFor="let info of data?.read_info">
                      <span *ngIf="info?.status === 1">
                        <b *ngIf="info?.status === 1">Acknowledge :</b>
                        <b *ngIf="info?.status === 2">OK :</b>
                        <b *ngIf="info?.status === 3">Close :</b>  {{info.user_name}}
                        <span *ngIf="info?.user_type === 1">(Admin)</span>
                        <span *ngIf="info?.user_type === 2">(User)</span>
                        <span *ngIf="info?.user_type === 3">(Hospital User)</span><br>
                        <span *ngIf="info?.read_at" ><b>Time : </b> {{info?.read_at | timeAgo}}<br></span>
                      </span>
                    </span>

                  </a>
                </div>
              </div>

            </perfect-scrollbar>
          </div>
        </div>
      </div>

      <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton"
        (click)="fullScreenClick()">
        <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
        <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
      </button>

    </div>

    <div class="user d-inline-block" dropdown>
      <button class="btn btn-empty p-0" type="button" dropdownToggle>
        <span class="name">{{displayName}}</span>
        <span>
          <img alt="Profile Picture" [src]="helper.image_url+'web_images/logo.png'" />
        </span>
      </button>

      <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
        <!-- <a class="dropdown-item c-pointer">Account</a>
        <a class="dropdown-item c-pointer">Features</a>
        <a class="dropdown-item c-pointer">History</a>
        <a class="dropdown-item c-pointer">Support</a>
        <li class="divider dropdown-divider"></li> -->
        <a class="dropdown-item c-pointer" (click)="onSignOut()">Sign out</a>
      </div>
    </div>

  </div>
  <audio id="music" src="assets/img/notifications/sos.mp3" style="display: none;"></audio>

  <audio id="musicVS" src="assets/img/notifications/vs.wav" style="display: none;"></audio>
</nav>

<!-- <app-notify-modal #notifyModal></app-notify-modal> -->
<style>
.modal-backdrop.show {
    opacity: .5;
}

.modal-backdrop.fade {
    opacity: 0;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background: no-repeat rgba(255, 0, 0, 0.4) !important;
}


.ACKNOWLEDGE{
    background: #13AE10;
    color: #FFFFFF;
    border-radius: 30px;
    height: 45px;
    width: 180px;
}

.close{
    background: red;
    color: #FFFFFF;
    border-radius: 30px;
    height: 45px;
    width: 180px;
}
</style>

<ng-template #template>
  <div class="modal-body" *ngIf="notificationData?.notify_type == 27 || notificationData?.notify_type == 28 ;else body">
    <div aria-labelledby="swal2-title" aria-describedby="swal2-content" class="swal2-popup swal2-modal swal2-show"
      tabindex="-1" role="dialog" aria-live="assertive" aria-modal="true"
      style="width: 1200px;background: url(&quot;/images/trees.png&quot;) rgb(255, 255, 255); display: flex; padding-bottom: 0px !important;padding-top: 0px !important;">
      <div class="row">
        <div class="col-6">
          <div class="swal2-header">
            <h2 class="swal2-title" id="swal2-title" style="display: flex;">{{notificationData?.notify_message}}</h2>
            <img class="swal2-image" (click)="close()"
              [src]="notificationData?.patient_detail?.profile_image ? (helper.image_url + notificationData?.patient_detail?.profile_image):(helper.image_url+'default.png')"
              alt="" style="width: 150px; height: 150px;">
          </div>
          <div class="swal2-content">
            <div id="swal2-content" class="swal2-html-container" style="display: block;">
              <span><b>User : </b> {{notificationData?.patient_detail?.name_title}}
                {{notificationData?.patient_detail?.first_name}} {{notificationData?.patient_detail?.last_name}}
                <br></span>
              <span><b>Mobile : </b> {{notificationData?.patient_detail?.mobile_no}} <br></span>
              <span><b>Message : </b> {{notificationData?.notify_message}} <br></span>
              <span><b>Device : </b> {{notificationData?.attribute}} {{notificationData.ble_address}} <br></span>
              <span><b>Gateway : </b> {{notificationData?.box_mac_address}} {{notificationData.box_name}} <br></span>
              <span><b>Notify : </b> {{datepipe.transform(notificationData?.created_at, 'dd/MM/yyyy, hh:mm:ss:mm a')}} <br></span>

              <br>
              <!-- <span *ngIf="notificationData?.admin_read_detail?._id" ><b>Acknowledge By : </b> {{notificationData?.admin_read_detail?.username}} (ADMIN) <br></span>
                <span *ngIf="notificationData?.read_admin_at" ><b>Acknowledge Time : </b> {{datepipe.transform(notificationData?.read_admin_at, 'dd/MM/yyyy, hh:mm:ss:mm a')}}<br></span> -->

                <!-- <span *ngIf="notificationData?.user_read_detail?._id" ><b>Acknowledge By : </b> {{notificationData?.user_read_detail?.user_title}} {{notificationData?.user_read_detail?.first_name}} {{notificationData?.user_read_detail?.last_name}} (USER) <br></span>
                <span *ngIf="notificationData?.read_user_at" ><b>Acknowledge Time : </b> {{datepipe.transform(notificationData?.read_user_at, 'dd/MM/yyyy, hh:mm:ss:mm a')}}<br></span> -->

                <!-- <span *ngIf="notificationData?.hospital_read_detail?._id" ><b>Acknowledge By : </b> {{notificationData?.hospital_read_detail?.user_title}} {{notificationData?.hospital_read_detail?.first_name}} {{notificationData?.hospital_read_detail?.last_name}} (HOSPITAL USER) <br></span>
                <span *ngIf="notificationData?.read_hospital_user_at" ><b>Acknowledge Time : </b> {{datepipe.transform(notificationData?.read_hospital_user_at, 'dd/MM/yyyy, hh:mm:ss:mm a')}}<br></span>   -->
                <span  *ngFor="let info of notificationData?.read_info">
                  <span *ngIf="info?.status === 1">
                    <b *ngIf="info?.status === 1">Acknowledge :</b>
                    <b *ngIf="info?.status === 2">OK :</b>
                    <b *ngIf="info?.status === 3">Close :</b>  {{info.user_name}}
                    <span *ngIf="info?.user_type === 1">(Admin)</span>
                    <span *ngIf="info?.user_type === 2">(User)</span>
                    <span *ngIf="info?.user_type === 3">(Hospital User)</span><br>
                    <span *ngIf="info?.read_at" ><b>Time : </b> {{info?.read_at | timeAgo}}</span><br>
                  </span>
                </span>

              </div>
          </div>
        </div>
        <div class="col-6" *ngIf="is_show_map">
          <div id="patient_map" style="min-width: 400px; min-height: 400px;margin-top: 25px;"></div>
        </div>
      </div>
      <div class="swal2-actions">
        <button type="button" (click)="isConfirmed(2);" class="ACKNOWLEDGE swal2-confirm swal2-styled" aria-label=""
          style="display: inline-block;"><b>OK</b></button>
          <button type="button" (click)="isConfirmed(3);" class="close swal2-confirm swal2-styled" aria-label=""
          style="display: inline-block;"><b>Close</b></button>
      </div>
    </div>
  </div>

  <ng-template #body>
    <div class="modal-body">
      <div aria-labelledby="swal2-title" aria-describedby="swal2-content" class="swal2-popup swal2-modal swal2-show"
        tabindex="-1" role="dialog" aria-live="assertive" aria-modal="true"
        style="width: 1200px;background: url(&quot;/images/trees.png&quot;) rgb(255, 255, 255); display: flex; padding-bottom: 0px !important;padding-top: 0px !important;">
        <div class="row">
          <div class="col-6">
            <div class="swal2-header">
              <h2 class="swal2-title" id="swal2-title" style="display: flex;">Vital Sign Alarm</h2>
              <img class="swal2-image" (click)="close()"
                [src]="notificationData?.patient_detail?.profile_image ? (helper.image_url + notificationData?.patient_detail?.profile_image):(helper.image_url+'default.png')"
                alt="" style="width: 150px; height: 150px;">
            </div>
            <div class="swal2-content">
              <div id="swal2-content" class="swal2-html-container" style="display: block;">
                <span style="font-size: larger;"><b>{{notificationData?.patient_detail?.name_title}} {{notificationData?.patient_detail?.first_name}} {{notificationData?.patient_detail?.last_name}}
                  </b><br></span>
                <span><b>Hospital : </b> {{notificationData?.hospital_detail?.en_name}} <br></span>
                <span><b>Mobile : </b> {{notificationData?.patient_detail?.mobile_no}} <br></span>
                <span *ngFor="let data of notificationData?.underlying_disease_detail"> {{data.en_name}} , </span>
                <span> {{notificationData?.patient_detail?.other_underlying_desease}}</span>

                <br><br><span style="font-size: larger;"><b>{{notificationData?.notify_message}}</b></span><br><br>

                <span><b>Device : </b> {{notificationData?.attribute}} {{notificationData.ble_address}} <br></span>
                <span><b>Gateway : </b> {{notificationData?.box_mac_address}} {{notificationData.box_name}} <br></span>
                <span><b>Notify : </b> {{datepipe.transform(notificationData?.created_at, 'dd/MM/yyyy, hh:mm:ss:mm a')}}<br></span>

                <!-- <span *ngIf="notificationData?.admin_read_detail?._id" ><b>Acknowledge By : </b> {{notificationData?.admin_read_detail?.username}} (ADMIN) <br></span>
                <span *ngIf="notificationData?.read_admin_at" ><b>Acknowledge Time : </b> {{datepipe.transform(notificationData?.read_admin_at, 'dd/MM/yyyy, hh:mm:ss:mm a')}}<br></span> -->

                <!-- <span *ngIf="notificationData?.user_read_detail?._id" ><b>Acknowledge By : </b> {{notificationData?.user_read_detail?.user_title}} {{notificationData?.user_read_detail?.first_name}} {{notificationData?.user_read_detail?.last_name}} (USER) <br></span>
                <span *ngIf="notificationData?.read_user_at" ><b>Acknowledge Time : </b> {{datepipe.transform(notificationData?.read_user_at, 'dd/MM/yyyy, hh:mm:ss:mm a')}}<br></span> -->

                <!-- <span *ngIf="notificationData?.hospital_read_detail?._id" ><b>Acknowledge By : </b> {{notificationData?.hospital_read_detail?.user_title}} {{notificationData?.hospital_read_detail?.first_name}} {{notificationData?.hospital_read_detail?.last_name}} (HOSPITAL USER) <br></span>
                <span *ngIf="notificationData?.read_hospital_user_at" ><b>Acknowledge Time : </b> {{datepipe.transform(notificationData?.read_hospital_user_at, 'dd/MM/yyyy, hh:mm:ss:mm a')}}<br></span>   -->


                <span  *ngFor="let info of notificationData?.read_info">
                  <span *ngIf="info?.status === 1">
                    <b *ngIf="info?.status === 1">Acknowledge :</b>
                    <b *ngIf="info?.status === 2">OK :</b>
                    <b *ngIf="info?.status === 3">Close :</b>  {{info.user_name}}
                    <span *ngIf="info?.user_type === 1">(Admin)</span>
                    <span *ngIf="info?.user_type === 2">(User)</span>
                    <span *ngIf="info?.user_type === 3">(Hospital User)</span><br>
                    <span *ngIf="info?.read_at" ><b>Time : </b> {{info?.read_at | timeAgo}}</span><br>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="col-6" *ngIf="is_show_map">
            <div id="patient_map" style="min-width: 400px; min-height: 500px;margin-top: 25px;"></div>
          </div>
        </div>
        <div class="swal2-actions">
          <button type="button" (click)="isConfirmed(2);" class="ACKNOWLEDGE swal2-confirm swal2-styled" aria-label=""
            style="display: inline-block;"><b>OK</b></button>
            <button type="button" (click)="isConfirmed(3);" class="close swal2-confirm swal2-styled" aria-label=""
            style="display: inline-block;"><b>Close</b></button>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
