import { Injectable } from '@angular/core';
import { Helper } from '../shared/helper';
import { AuthService,Store } from '../shared/auth.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommonService {

  private store:Store;
  
  constructor(private helper: Helper,private _auth:AuthService) {
    this.store = this._auth.getStore();
  }

  private _providerChanges = new BehaviorSubject<any>(null);
  _providerObservable = this._providerChanges.asObservable()

  fetch_image_settings() {
    this.store = this._auth.getStore();
    let json = {
      store_id : this.store._id,
      server_token:this.store.servertoken
    }
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_IMAGE_SETTING, json)
  }

  order_detail(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.ORDER_DETAIL, json)
  }

  last_six_months_earnings(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.LAST_SIX_MONTH_EARNING_DETAIL, {})
  }

  last_fifteen_day_order_detail(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.LAST_FIFTEEN_DAY_ORDER_DETAIL, {})
  }

  last_six_month_payment_detail(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.LAST_SIX_MONTH_PAYMENT_DETAIL, {})
  }

  monthly_payment_detail(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.MONTHLY_PAYMENT_DETAIL, {})
  }

  get_app_name(){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.GET_APP_NAME, {})
  }

  add_wallet_amount(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.ADD_WALLET, json).then(() => {
      this._providerChanges.next({})
    })
  }

  add_langauge(form_data){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.ADD_NEW_LANGUAGE, form_data)
  }

  getPrivacyPolicy(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_PRIVACY_POLICY,{})
  }

  addPrivacyPolicy(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.SET_PRIVACY_POLICY, json)
  }
}
