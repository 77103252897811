import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LangPipe } from './lang.pipe'
import { RoundPipe } from './round.pipe'
import { SearchPipe } from './search.pipe';
import { TimeAgoPipe } from './time-ago.pipe'

@NgModule({
  declarations: [LangPipe,RoundPipe,SearchPipe, TimeAgoPipe],
  imports: [CommonModule],
  exports: [LangPipe,RoundPipe,SearchPipe,TimeAgoPipe],
})
export class PipeModule {}
