import { Pipe, PipeTransform } from '@angular/core';
import { parse } from 'path';

@Pipe({
    name: 'roundpipe',    
})
export class RoundPipe implements PipeTransform {

    constructor(){}

    transform(value: any, fixed: number = 0): any {
        try{
            value = Number(value);
            if(value && !isNaN(value)){
                // return (value < 10 && value > 0) ? "0" + value.toFixed(fixed) : value.toFixed(fixed);
                return value.toFixed(fixed);
            }else{
                return Number('0').toFixed(fixed);
            }
        }catch(err){
            return Number('0').toFixed(fixed);
        }
    }
}