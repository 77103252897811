// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { UserRole } from '../app/shared/auth.roles';

export const environment = {
  production: false,
  buyUrl : 'https://1.envato.market/6NV1b',
  SCARF_ANALYTICS : false,
  adminRoot: '/app',

  // imageUrl: 'http://localhost:8000/',
  // apiUrl:  'http://192.168.0.190:8000/v1',
  // socketUrl:  'http://localhost:8000/',

  imageUrl: 'https://stardust.amyplatform.com/',
  apiUrl:  'https://stardust.amyplatform.com/v1',
  socketUrl:  'https://stardust.amyplatform.com/',
  aocApiURl : 'https://stardust.aocopt.com',

  mapboxToken : "pk.eyJ1Ijoia2l0a2Ftb24iLCJhIjoiY2x0NTlwanNhMDl0bDJqbWViZ3NmcHZ5eSJ9.6sMSW94GqkFRPUmhphggvg",



  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.Admin,
  firebase: {
    apiKey: 'AIzaSyCqoNLB_jTw4nncO12qR-eDH9gAeWiZVaw',
    authDomain: 'vien-angular-login.firebaseapp.com',
    databaseURL: 'https://vien-angular-login.firebaseio.com',
    projectId: 'vien-angular-login',
    storageBucket: 'vien-angular-login.appspot.com',
    messagingSenderId: '16217062888',
    appId: '1:16217062888:web:6b08232ca0c9662fedb85d',
    measurementId: 'G-8ETT79WRRN'
  }
};
